import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import DateFormat, {Format} from "../common/DateFormat";
import WebvertRating from "../common/WebvertRating";
import {Decarbonation} from "../../api/dto/Decarbonation";

type DecarbonationHeaderProps = {
    decarbonation: Decarbonation,
}

export default function DecarbonationHeader(props: DecarbonationHeaderProps) {
    const {t} = useTranslation('decarbonation')

    const [pageLoadingTimeAfter, setPageLoadingTimeAfter] = useState<Number>(0)

    useEffect(() => {
        if (props.decarbonation&&props.decarbonation.start_site_audit&&props.decarbonation.end_site_audit) {
            setPageLoadingTimeAfter((props.decarbonation.end_site_audit.total_pages_time / props.decarbonation.end_site_audit.audited_pages_count) / 1000)
        }
    }, [props]);

    return <div className="bg-white rounded rounded-lg  w-full p-4 mb-8">
        <h2>{t('report.title')}</h2>
        <p className="font-light">
            {t('report.done_the')} <DateFormat
            date={props.decarbonation.end_date ? props.decarbonation.end_date : props.decarbonation.creation_date}
            format={Format.TextualDay}/>
            &nbsp;
            {t('report.byOperator', {operator: props.decarbonation.operator})}
        </p>
        <div className="flex flex-col lg:flex-row lg:justify-between gap-2">
            <div className="flex flex-col justify-center my-4 gap-2">
                <div className="flex gap-2 items-center justify-center">
                    <svg width="24" height="24" viewBox="0 0 101 106" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M100.74 11.46V0.339988C78.47 5.89999 67.34 0.339988 56.21 0.339988C48.9051 0.33342 41.6704 1.7659 34.9191 4.55563C28.1678 7.34535 22.0321 11.4377 16.8626 16.5989C11.693 21.7601 7.59074 27.8891 4.7901 34.6359C1.98946 41.3826 0.54529 48.615 0.540039 55.92C0.540039 68.24 1.19003 78 2.47003 85.19C1.42392 90.4545 0.808554 95.7955 0.630035 101.16C0.612495 101.784 0.721212 102.406 0.949677 102.987C1.17814 103.569 1.52164 104.098 1.95959 104.543C2.39755 104.989 2.92095 105.341 3.49838 105.579C4.07582 105.818 4.69541 105.937 5.32004 105.93C8.50004 105.93 10.78 102.8 11.14 99.64C11.8 94.01 15.39 89.49 19.04 88.31C24.93 86.39 31.58 87.97 37.47 88.31C71.18 90.21 100.74 64.96 100.74 11.46Z"
                            fill="#A7E950"/>
                        <path opacity="0.6"
                              d="M100.73 11.46V0.339966C60.17 67.66 1.98001 42.87 0.530006 101.18C0.520651 101.804 0.636124 102.423 0.869636 103.002C1.10315 103.58 1.44998 104.106 1.88975 104.549C2.32951 104.991 2.85333 105.342 3.4304 105.579C4.00746 105.816 4.62613 105.935 5.25001 105.93C8.46001 105.93 10.76 102.76 11.15 99.57C11.82 93.97 15.4 89.48 19.03 88.3C24.93 86.4 31.58 87.97 37.46 88.3C71.18 90.2 100.73 64.96 100.73 11.46Z"
                              fill="#71B029"/>
                        <path
                            d="M54.88 45.75V61.89C54.88 62.6759 54.5685 63.4298 54.0137 63.9865C53.4589 64.5432 52.706 64.8573 51.9201 64.86H45.12C44.3323 64.86 43.5769 64.5471 43.0199 63.9901C42.463 63.4331 42.15 62.6777 42.15 61.89V45.75H33.3901C32.8047 45.748 32.233 45.573 31.7468 45.2472C31.2605 44.9213 30.8814 44.4591 30.6571 43.9184C30.4327 43.3778 30.373 42.783 30.4857 42.2086C30.5983 41.6342 30.8781 41.1058 31.29 40.69L46.4201 25.56C46.977 25.003 47.7324 24.6902 48.52 24.6902C49.3077 24.6902 50.0631 25.003 50.62 25.56L65.74 40.69C66.1519 41.1058 66.4318 41.6342 66.5444 42.2086C66.657 42.783 66.5974 43.3778 66.373 43.9184C66.1487 44.4591 65.7695 44.9213 65.2833 45.2472C64.7971 45.573 64.2254 45.748 63.6401 45.75H54.88Z"
                            fill="#21335E"/>
                    </svg>
                    <p className="text-xl font-bold ">{t('data.commercialGain.label')}</p>
                </div>
                <div className="text-4xl font-bold text-accent text-center">
                    {props.decarbonation.speed_percent_gain > 0 ? "+" : ""}
                    {props.decarbonation.speed_percent_gain.toFixed(0)}%
                </div>

            </div>
            <div className="flex flex-col justify-center my-4 gap-2">
                <div className="flex gap-2 items-center justify-center">
                    <svg width="24" height="24" viewBox="0 0 114 106" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M91.53 14.24C91.7924 13.9898 91.9737 13.6665 92.0502 13.3121C92.1268 12.9576 92.0951 12.5884 91.9594 12.2522C91.8236 11.9159 91.5899 11.6282 91.2887 11.4264C90.9875 11.2245 90.6326 11.1178 90.27 11.12H85C83.7764 11.1173 82.6039 10.6294 81.7396 9.76328C80.8754 8.89714 80.39 7.72354 80.39 6.49997V4.95998C80.39 3.73641 80.8754 2.5628 81.7396 1.69667C82.6039 0.830539 83.7764 0.342614 85 0.339966H108.71C109.935 0.339966 111.11 0.826712 111.977 1.69313C112.843 2.55955 113.33 3.73468 113.33 4.95998V28.66C113.331 29.2675 113.213 29.8694 112.981 30.431C112.75 30.9927 112.41 31.5032 111.98 31.9333C111.551 32.3633 111.041 32.7045 110.48 32.9373C109.919 33.1701 109.318 33.29 108.71 33.29H107.17C105.945 33.29 104.77 32.8032 103.903 31.9368C103.037 31.0704 102.55 29.8953 102.55 28.67V24.66C102.55 24.3007 102.443 23.9496 102.243 23.6513C102.043 23.3529 101.759 23.1209 101.426 22.9847C101.094 22.8485 100.728 22.8144 100.376 22.8865C100.024 22.9587 99.702 23.1339 99.45 23.39L55.62 67.77C55.1513 68.2442 54.5855 68.6113 53.9615 68.8462C53.3375 69.0811 52.67 69.1781 52.0049 69.1306C51.3399 69.0831 50.693 68.8922 50.1087 68.571C49.5244 68.2498 49.0165 67.806 48.62 67.27L36.93 51.53C36.5142 50.971 35.9774 50.5132 35.3598 50.191C34.7421 49.8687 34.0595 49.6904 33.3632 49.6692C32.6668 49.648 31.9747 49.7847 31.3386 50.0688C30.7025 50.3529 30.1389 50.7772 29.69 51.31L15.72 67.95C14.92 68.9 13.42 68.75 13.12 67.55C12.13 63.62 13.32 58.75 12.95 53.35C12.9296 53.0782 12.9681 52.8052 13.063 52.5498C13.158 52.2943 13.307 52.0624 13.5 51.87L30.04 35.77C30.4837 35.3406 31.0089 35.0046 31.5847 34.7816C32.1605 34.5587 32.7751 34.4535 33.3922 34.4721C34.0094 34.4908 34.6165 34.6329 35.1778 34.8902C35.739 35.1475 36.243 35.5146 36.66 35.97L48.63 49C49.0467 49.4523 49.5495 49.8169 50.1089 50.0723C50.6684 50.3278 51.2732 50.4689 51.8879 50.4875C52.5027 50.5062 53.1149 50.4019 53.6888 50.1808C54.2627 49.9597 54.7867 49.6262 55.23 49.2L91.53 14.24Z"
                            fill="#8CD935"/>
                        <path
                            d="M17.44 66.22C18.879 68.6556 20.6194 70.8999 22.62 72.9C28.9396 79.2172 37.5094 82.766 46.445 82.766C55.3806 82.766 63.9504 79.2172 70.27 72.9C76.5875 66.5823 80.1376 58.0144 80.14 49.08C80.14 47.18 83.76 43.94 86.91 41.3C88.73 39.77 91.7 40.73 92.01 43.1C92.4615 46.5421 92.5219 50.0243 92.19 53.48C91.4177 61.4687 88.5664 69.1158 83.92 75.66C83.4 76.4 83.46 77.42 84.1 78.06L101.56 95.53C102.66 96.6293 103.3 98.1059 103.35 99.66C103.397 101.216 102.85 102.732 101.82 103.9C100.789 105.07 99.3519 105.803 97.8 105.95C96.2503 106.098 94.7023 105.651 93.47 104.7L92.9 104.19L75.43 86.73C75.1162 86.4205 74.7026 86.2325 74.263 86.1995C73.8235 86.1666 73.3865 86.2907 73.03 86.55C67.5182 90.4601 61.2117 93.1063 54.56 94.3C47.3762 95.5879 39.9889 95.1457 33.01 93.01C26.0328 90.877 19.6638 87.1132 14.43 82.03C9.19921 76.9464 5.2538 70.6907 2.92002 63.78C0.584181 56.8672 -0.0718266 49.4976 1.00632 42.281C2.08447 35.0643 4.86575 28.2082 9.12 22.28C13.3752 16.3555 18.9797 11.5291 25.47 8.2C31.9617 4.86856 39.1534 3.13065 46.45 3.13001C55.0118 3.12773 63.4039 5.51755 70.68 10.03C73.2254 11.6139 75.61 13.4426 77.8 15.49C78.3785 16.0625 78.7147 16.8355 78.7389 17.649C78.7631 18.4625 78.4734 19.2542 77.93 19.86C75.42 22.73 71.78 26.76 70.27 25.26C63.9504 18.9428 55.3806 15.394 46.445 15.394C37.5094 15.394 28.9396 18.9428 22.62 25.26C19.0322 28.8481 16.3009 33.2 14.63 37.9912C12.9591 42.7823 12.3916 47.8889 12.97 52.93C13.77 58.59 14.61 61.49 17.44 66.22Z"
                            fill="#21335E"/>
                    </svg>
                    <p className="text-xl font-bold ">{t('data.commercialGain.labelPage')}</p>
                </div>
                <p className={"text-3xl font-bold text-center " + ((pageLoadingTimeAfter <= 1) ? " text-success-content " : ((pageLoadingTimeAfter > 1 && pageLoadingTimeAfter < 5) ? " text-warning " : " text-error-content "))}>
                    {pageLoadingTimeAfter.toFixed(2)}s
                </p>
            </div>

            <div className="flex flex-col justify-center my-4 gap-2">
                <div className="flex gap-2 items-center justify-center">
                    <svg width="24" height="24" viewBox="0 0 114 106" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M91.53 14.24C91.7924 13.9898 91.9737 13.6665 92.0502 13.3121C92.1268 12.9576 92.0951 12.5884 91.9594 12.2522C91.8236 11.9159 91.5899 11.6282 91.2887 11.4264C90.9875 11.2245 90.6326 11.1178 90.27 11.12H85C83.7764 11.1173 82.6039 10.6294 81.7396 9.76328C80.8754 8.89714 80.39 7.72354 80.39 6.49997V4.95998C80.39 3.73641 80.8754 2.5628 81.7396 1.69667C82.6039 0.830539 83.7764 0.342614 85 0.339966H108.71C109.935 0.339966 111.11 0.826712 111.977 1.69313C112.843 2.55955 113.33 3.73468 113.33 4.95998V28.66C113.331 29.2675 113.213 29.8694 112.981 30.431C112.75 30.9927 112.41 31.5032 111.98 31.9333C111.551 32.3633 111.041 32.7045 110.48 32.9373C109.919 33.1701 109.318 33.29 108.71 33.29H107.17C105.945 33.29 104.77 32.8032 103.903 31.9368C103.037 31.0704 102.55 29.8953 102.55 28.67V24.66C102.55 24.3007 102.443 23.9496 102.243 23.6513C102.043 23.3529 101.759 23.1209 101.426 22.9847C101.094 22.8485 100.728 22.8144 100.376 22.8865C100.024 22.9587 99.702 23.1339 99.45 23.39L55.62 67.77C55.1513 68.2442 54.5855 68.6113 53.9615 68.8462C53.3375 69.0811 52.67 69.1781 52.0049 69.1306C51.3399 69.0831 50.693 68.8922 50.1087 68.571C49.5244 68.2498 49.0165 67.806 48.62 67.27L36.93 51.53C36.5142 50.971 35.9774 50.5132 35.3598 50.191C34.7421 49.8687 34.0595 49.6904 33.3632 49.6692C32.6668 49.648 31.9747 49.7847 31.3386 50.0688C30.7025 50.3529 30.1389 50.7772 29.69 51.31L15.72 67.95C14.92 68.9 13.42 68.75 13.12 67.55C12.13 63.62 13.32 58.75 12.95 53.35C12.9296 53.0782 12.9681 52.8052 13.063 52.5498C13.158 52.2943 13.307 52.0624 13.5 51.87L30.04 35.77C30.4837 35.3406 31.0089 35.0046 31.5847 34.7816C32.1605 34.5587 32.7751 34.4535 33.3922 34.4721C34.0094 34.4908 34.6165 34.6329 35.1778 34.8902C35.739 35.1475 36.243 35.5146 36.66 35.97L48.63 49C49.0467 49.4523 49.5495 49.8169 50.1089 50.0723C50.6684 50.3278 51.2732 50.4689 51.8879 50.4875C52.5027 50.5062 53.1149 50.4019 53.6888 50.1808C54.2627 49.9597 54.7867 49.6262 55.23 49.2L91.53 14.24Z"
                            fill="#8CD935"/>
                        <path
                            d="M17.44 66.22C18.879 68.6556 20.6194 70.8999 22.62 72.9C28.9396 79.2172 37.5094 82.766 46.445 82.766C55.3806 82.766 63.9504 79.2172 70.27 72.9C76.5875 66.5823 80.1376 58.0144 80.14 49.08C80.14 47.18 83.76 43.94 86.91 41.3C88.73 39.77 91.7 40.73 92.01 43.1C92.4615 46.5421 92.5219 50.0243 92.19 53.48C91.4177 61.4687 88.5664 69.1158 83.92 75.66C83.4 76.4 83.46 77.42 84.1 78.06L101.56 95.53C102.66 96.6293 103.3 98.1059 103.35 99.66C103.397 101.216 102.85 102.732 101.82 103.9C100.789 105.07 99.3519 105.803 97.8 105.95C96.2503 106.098 94.7023 105.651 93.47 104.7L92.9 104.19L75.43 86.73C75.1162 86.4205 74.7026 86.2325 74.263 86.1995C73.8235 86.1666 73.3865 86.2907 73.03 86.55C67.5182 90.4601 61.2117 93.1063 54.56 94.3C47.3762 95.5879 39.9889 95.1457 33.01 93.01C26.0328 90.877 19.6638 87.1132 14.43 82.03C9.19921 76.9464 5.2538 70.6907 2.92002 63.78C0.584181 56.8672 -0.0718266 49.4976 1.00632 42.281C2.08447 35.0643 4.86575 28.2082 9.12 22.28C13.3752 16.3555 18.9797 11.5291 25.47 8.2C31.9617 4.86856 39.1534 3.13065 46.45 3.13001C55.0118 3.12773 63.4039 5.51755 70.68 10.03C73.2254 11.6139 75.61 13.4426 77.8 15.49C78.3785 16.0625 78.7147 16.8355 78.7389 17.649C78.7631 18.4625 78.4734 19.2542 77.93 19.86C75.42 22.73 71.78 26.76 70.27 25.26C63.9504 18.9428 55.3806 15.394 46.445 15.394C37.5094 15.394 28.9396 18.9428 22.62 25.26C19.0322 28.8481 16.3009 33.2 14.63 37.9912C12.9591 42.7823 12.3916 47.8889 12.97 52.93C13.77 58.59 14.61 61.49 17.44 66.22Z"
                            fill="#21335E"/>
                    </svg>
                    <p className="text-xl font-bold">{t('data.googlePerformance.label')}</p>
                </div>
                <div className="text-center">
                                <span className="text-4xl font-bold text-accent">
                                    {props.decarbonation.seo_gain > 0 ? "+" : ""}
                                    {props.decarbonation.seo_gain.toFixed(0)}
                                </span>
                    &nbsp;
                    <span className="text-xs text-neutral-focus">{t('data.googlePerformance.points')}</span>
                </div>
            </div>
            <div className="flex flex-col justify-center my-4 gap-2">
                <div className="flex gap-2 items-center justify-center">
                    <svg width="24" height="24" viewBox="0 0 101 106" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M100.74 11.46V0.339988C78.47 5.89999 67.34 0.339988 56.21 0.339988C48.9051 0.33342 41.6704 1.7659 34.9191 4.55563C28.1678 7.34535 22.0321 11.4377 16.8626 16.5989C11.693 21.7601 7.59074 27.8891 4.7901 34.6359C1.98946 41.3826 0.54529 48.615 0.540039 55.92C0.540039 68.24 1.19003 78 2.47003 85.19C1.42392 90.4545 0.808554 95.7955 0.630035 101.16C0.612495 101.784 0.721212 102.406 0.949677 102.987C1.17814 103.569 1.52164 104.098 1.95959 104.543C2.39755 104.989 2.92095 105.341 3.49838 105.579C4.07582 105.818 4.69541 105.937 5.32004 105.93C8.50004 105.93 10.78 102.8 11.14 99.64C11.8 94.01 15.39 89.49 19.04 88.31C24.93 86.39 31.58 87.97 37.47 88.31C71.18 90.21 100.74 64.96 100.74 11.46Z"
                            fill="#A7E950"/>
                        <path opacity="0.6"
                              d="M100.73 11.46V0.339966C60.17 67.66 1.98001 42.87 0.530006 101.18C0.520651 101.804 0.636124 102.423 0.869636 103.002C1.10315 103.58 1.44998 104.106 1.88975 104.549C2.32951 104.991 2.85333 105.342 3.4304 105.579C4.00746 105.816 4.62613 105.935 5.25001 105.93C8.46001 105.93 10.76 102.76 11.15 99.57C11.82 93.97 15.4 89.48 19.03 88.3C24.93 86.4 31.58 87.97 37.46 88.3C71.18 90.2 100.73 64.96 100.73 11.46Z"
                              fill="#71B029"/>
                        <path
                            d="M54.88 45.75V61.89C54.88 62.6759 54.5685 63.4298 54.0137 63.9865C53.4589 64.5432 52.706 64.8573 51.9201 64.86H45.12C44.3323 64.86 43.5769 64.5471 43.0199 63.9901C42.463 63.4331 42.15 62.6777 42.15 61.89V45.75H33.3901C32.8047 45.748 32.233 45.573 31.7468 45.2472C31.2605 44.9213 30.8814 44.4591 30.6571 43.9184C30.4327 43.3778 30.373 42.783 30.4857 42.2086C30.5983 41.6342 30.8781 41.1058 31.29 40.69L46.4201 25.56C46.977 25.003 47.7324 24.6902 48.52 24.6902C49.3077 24.6902 50.0631 25.003 50.62 25.56L65.74 40.69C66.1519 41.1058 66.4318 41.6342 66.5444 42.2086C66.657 42.783 66.5974 43.3778 66.373 43.9184C66.1487 44.4591 65.7695 44.9213 65.2833 45.2472C64.7971 45.573 64.2254 45.748 63.6401 45.75H54.88Z"
                            fill="#21335E"/>
                    </svg>
                    <p className="text-xl font-bold">{t('data.ecologicalGain.label')}</p>
                </div>
                <div className="text-center">
                                <span className="text-4xl font-bold text-accent">
                                    {props.decarbonation.eq_kg_co2_gain.toFixed(0)}
                                </span>
                    &nbsp;
                    <span className="text-xs text-neutral-focus">{t('data.ecologicalGain.co2Eq')}</span>
                </div>
            </div>
            <div className="flex flex-col justify-center my-4 gap-2">
                <div className="flex gap-2 items-center justify-center">
                    <WebvertRating rating={props.decarbonation.end_site_audit?.webvert_rating!}/>
                    <p className="text-xl font-bold">{t('audit:data.webvertScore.label')}</p>
                </div>
                <div>
                    <div className="flex justify-center text-center items-end">
                                    <span
                                        className="text-4xl font-bold text-accent">{props.decarbonation.end_site_audit?.webvert_score.toFixed(2)}</span>
                        &nbsp;
                        <span className="text-xs text-neutral-focus flex flex-col">
                                        <span>{t('audit:data.webvertScore.kwhPage')}</span>
                                        <span>{t('audit:data.webvertScore.10kVisits')}</span>
                                    </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

}
