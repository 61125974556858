import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useMyTransactions} from "../../api/creditsHook";
import {CreditsTransaction} from "../../api/dto/Credits";
import {useGetUser} from "../../api/userHook";
import DateFormat, {Format} from "../../components/common/DateFormat";
import Pagination from "../../components/pagination";
import {Link} from "react-router-dom";
import ROUTES_CLIENT from "../../constants/routesClient";
import ClientModalHeader from "../../components/common/ClientModalHeader";
import CreditsLeft from "../../components/common/CreditsLeft";

const pageSize = 10

export default function CheckCredits() {
    const {t} = useTranslation('credits')
    const [page, setPage] = useState<number>(1)

    const {data: user} = useGetUser()

    const {isLoading, error, data: transactions} = useMyTransactions(
        pageSize,
        (page - 1) * pageSize
    )

    if (isLoading) {
        return <div>{t('management.loading')}</div>
    }


    const renderReason = (param: string) => {
        switch (param) {
            case 'signup':
                return <div className="badge badge-primary">{t('reason.signup')}</div>;
            case 'refund':
                return <div className="badge badge-neutral">{t('reason.refund')}</div>;
            case 'manual':
                return <div className="badge badge-neutral">{t('reason.manual')}</div>;
            case 'by_customer':
                return <div className="badge badge-primary">{t('reason.by_customer')}</div>;
            case 'audit':
                return <div className="badge badge-accent">{t('reason.audit')}</div>;
            case 'fixes':
                return <div className="badge badge-secondary">{t('reason.fixes')}</div>;
            default:
                return <div className="badge">{param}</div>;
        }
    }

    return <div>
        <ClientModalHeader/>
        <div className="w-full min-h-screen bg-base-200 py-6 px-2">
            <div className="max-w-6xl mx-auto">
                <CreditsLeft user={user}/>
                <div className="flex flex-col lg:flex-row gap-2 justify-stretch lg:justify-between items-center mb-4 ">
                    <h1 className="text-3xl mx-0 w-full  text-left">{t('management.title')}</h1>
                    <Link to={ROUTES_CLIENT.CREDITS_BUY.path} className="btn btn-primary btn-sm my-4 lg:my-0 font-normal">{t('action.add')}</Link>
                </div>
                <div className="bg-base-100 pt-4 pb-16 px-2 rounded rounded-lg">
                    <div className="overflow-x-auto">
                        <table className="table table-zebra">
                            <thead>
                            <tr>
                                <th>{t('details.date')}</th>
                                <th>{t('details.type')}</th>
                                <th>{t('details.amount')}</th>
                                <th>{t('details.reason')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                transactions && transactions.results && transactions.results.length > 0 &&
                                transactions.results.map((transaction: CreditsTransaction) => (
                                    <tr key={transaction.id}>
                                        <td><DateFormat date={transaction.date} format={Format.NumericDateTime}/></td>
                                        <td>{transaction.amount > 0 ? t('credit') : t('debit')}</td>
                                        <td>{transaction.amount}</td>
                                        <td>{transaction.amount > 0 ? renderReason(transaction.credit_type) : renderReason(transaction.debit_type)}</td>
                                    </tr>
                                ))
                            }
                            {/* Credit transaction data goes here */}
                            </tbody>
                        </table>
                        {transactions &&
                            <div className="flex flex-col items-center mt-2">
                                <Pagination currentPage={page} pageSize={pageSize} totalCount={transactions.total_count}
                                            onClick={(i) => setPage(i)}/>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
}
