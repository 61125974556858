import {Site} from "./Site";
import {Technologies} from "./Technologies";

export interface SiteAudit {
    id: string
    site?: Site
    creation_date: Date
    start_date?: Date
    crawl_date?: Date
    audit_date?: Date
    aggregation_date?: Date
    done_date?: Date
    error_date?: Date
    error_message: string
    cancel_date?: Date
    original_site_audit_id?: string
    status: SiteAuditStatus
    tags: string[]
    settings: SiteAuditSettings
    bandwidth_savings_percent: number
    bandwidth_savings: number
    deduplicated_bandwidth_savings: number
    total_eq_co2: number
    eq_co2_savings: number
    total_car_km: number
    savings_car_km: number
    total_pages_time: number
    total_ms_savings: number
    ms_savings_percent: number
    total_page_size: number
    desktop_score_google: number
    mobile_score_google: number
    global_score_google: number
    webvert_score: number
    webvert_rating: string
    label_id: string
    one_shot_price: number
    subscription_price: number
    total_pages_count: number
    audited_pages_count: number
    error_pages_count: number
    technologies: Technologies
    details_by_service: SiteAuditDetailsByService[]
    access_to_fixes: boolean
    is_published: boolean
    published_token: string
}

export enum SiteAuditStatus {
    Done = "Done",
    Error = "Error",
    Canceled = "Canceled",
    Aggregate = "Aggregate",
    Auditing = "Auditing",
    Crawling = "Crawling",
    Pending = "Pending"
}

export interface SiteAuditSettings {
    max_urls_to_audit: number
}

export interface SiteAuditDetailsByService {
    service_code: string
    loading_time_saving: number
    bandwidth_saving_deduplicated: number
    bandwidth_saving: number
    count_assets_to_fix: number
    details_by_sub_category: SiteAuditDetailsBySubCategory[]
}

export interface SiteAuditDetailsBySubCategory {
    name: string
    bandwidth_saving: number
}

export interface SiteAuditCreation {
    site_id: string
    audit_tag: string
    max_urls: number
    use_webvert_user_agent: boolean
    team_that_spends_credits?: string
}

export interface SiteAuditReproduction {
    audit_id_to_reproduce: string
}
