import {useTranslation} from "react-i18next";
import {SiteAudit} from "../api/dto/SiteAudit";
import {CLIENT_CREDIT_FIXES, NO_ENOUGH_CREDIT, USE_USER_CREDITS} from "../constants/audit";
import ROUTES_CLIENT from "../constants/routesClient";
import {useNavigate} from "react-router-dom";
import info from '../assets/images/info.svg'
import fixes from '../assets/images/fixes.svg'
import {useBuyWorstAsset} from "../api/creditsHook";
import {NotificationManager} from "react-notifications";
import {ERROR_TIMEOUT} from "../constants/notifications";
import ModalV2 from "./common/ModalV2";
import React, {useEffect, useState} from "react";
import {useGetUser} from "../api/userHook";
import GoAssetFixesButton from "./GoAssetFixesButton";
import {useGetTeams} from "../api/teamHook";
import {canBuyAccessToAssets, usableTeamCredits} from "../utils/utils";
import {Team} from "../api/dto/Team";

interface Props {
    siteAudit: SiteAudit
}


export default function BuyAssetFixesButton(props: Props) {
    const siteAudit = props.siteAudit
    const {t} = useTranslation('credits')
    const navigate = useNavigate()

    const [isOpened, setIsOpened] = useState(false)
    const [creditSource, setCreditSource] = useState<string>(USE_USER_CREDITS)

    const [teamsWithUsableCredit, setTeamsWithUsableCredit] = useState<Team[]>([])

    const {data: user} = useGetUser()

    const {data: teams} = useGetTeams(siteAudit.site?.id ? siteAudit.site.id : '', true)

    useEffect(() => {
            if (siteAudit && siteAudit.site && user && teams) {
                const teamList = usableTeamCredits(siteAudit.site?.id, user, teams)
                setTeamsWithUsableCredit(teamList)
                if (teamList.length > 0) {
                    setCreditSource(teamList[0].id)
                }
            }
        }, [teams, user]
    );

    const buyWorstAssetMutation = useBuyWorstAsset(
        () => {
            navigate(ROUTES_CLIENT.AUDIT_FIXES_VIEW.withParams(siteAudit.id))
        },
        (error) => {
            if (error.message === NO_ENOUGH_CREDIT) {
                NotificationManager.error(t('error.message'), t('error.title'), ERROR_TIMEOUT)
            }

        })


    const handleBuy = () => {
        if (creditSource === USE_USER_CREDITS) {
            buyWorstAssetMutation.mutate({id: siteAudit.id, buyAssets: {}})
        } else {
            buyWorstAssetMutation.mutate(
                {
                    id: siteAudit.id,
                    buyAssets: {
                        team_that_spends_credits: creditSource
                    }
                }
            )
        }
    }


    if (siteAudit.access_to_fixes) {
        return <GoAssetFixesButton siteAudit={siteAudit}/>
    } else {
        return <div className="flex flex-col items-center">
            {
                user && teams && siteAudit.site && canBuyAccessToAssets(siteAudit.site.id, user, teams) ?
                    <>
                        <button
                            className="btn btn-outline btn-lg w-full"
                            onClick={() => setIsOpened(true)}
                        >
                            {t('audit:actions.see.fixes')}
                        </button>
                        <p className="text-xs"><img src={info} aria-hidden={true} alt="Info" className="inline"/> <span className="align-middle">{t('cost')} {CLIENT_CREDIT_FIXES} {CLIENT_CREDIT_FIXES > 1 ? t('items') : t('item')}</span></p>
                    </> :
                    <>
                        <p className="text-s font-bold mt-2">{t('confirm.fixes.not_possible')}</p>
                    </>
            }
            <ModalV2 id="buy-fixes" visible={isOpened} onClose={() => setIsOpened(false)}>
                <>
                    <h3>{t('confirm.fixes.title')}</h3>
                    <p className="text-neutral-focus text-sm mb-2">
                        {t('confirm.fixes.message')}
                    </p>
                    <div className="relative inline-block  w-full">
                        <div className="my-4 w-full">

                            {user && <>
                                {teamsWithUsableCredit.length > 0 ?
                                    <div className="pb-4">
                                        <legend className="font-bold">
                                            {t('confirm.fixes.credits_source')}
                                        </legend>
                                        {
                                            teamsWithUsableCredit.map((currentTeam, i) => {
                                                return <div className="pl-8 mt-2" key={currentTeam.id}>
                                                    <input type="radio"
                                                           id={currentTeam.id}
                                                           name="creditsource"
                                                           value={currentTeam.id}
                                                           aria-labelledby={"team-" + currentTeam.id}
                                                           checked={creditSource === currentTeam.id}
                                                           onChange={e => setCreditSource(currentTeam.id)}
                                                    />
                                                    <label className="pl-2 label-text"
                                                           id={"team-" + currentTeam.id}
                                                           htmlFor={currentTeam.id}
                                                    >
                                                        {t('confirm.fixes.credits_team')} "{currentTeam.name}" : {currentTeam.credits} {t('management.available', {s: (currentTeam.credits > 0 ? "s" : "")})}
                                                    </label>
                                                </div>
                                            })
                                        }
                                        <div className="pl-8 mt-2">
                                            <input type="radio"
                                                   id="user-credit"
                                                   name="creditsource"
                                                   value={USE_USER_CREDITS}
                                                   aria-labelledby="user-credit"
                                                   checked={creditSource === USE_USER_CREDITS}
                                                   onChange={e => setCreditSource(USE_USER_CREDITS)}
                                            />
                                            <label className="pl-2 label-text" id="user-credit" htmlFor="user-credit">
                                                {t('confirm.fixes.credits_user')} {user?.credits} {t('management.available', {s: (user!.credits > 0 ? "s" : "")})}
                                            </label>
                                        </div>
                                    </div>
                                    :
                                    <p className="text-center text-xs">
                                        <img src={info} aria-hidden={true} alt="Info" className="inline"/>
                                        <span className="align-middle">
                                &nbsp;
                                            {user?.credits} {t('management.available', {s: (user!.credits > 0 ? "s" : "")})}
                            </span>
                                    </p>
                                }
                            </>
                            }
                            <button onClick={handleBuy}
                                    className="w-full btn btn-lg btn-primary">{t('confirm.fixes.button', {
                                nb: CLIENT_CREDIT_FIXES,
                                s: (CLIENT_CREDIT_FIXES > 1 ? "s" : "")
                            })}</button>
                            <img src={fixes} alt="" className="absolute -right-2 top-2/3 transform -translate-y-1/3"
                                 aria-hidden="true"/>
                        </div>
                    </div>
                </>
            </ModalV2>
        </div>
    }
}
