import DateFormat, {Format} from "../common/DateFormat";
import React, {useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useDeleteTeamMember, useGetTeam} from "../../api/teamHook";
import ModalV2 from "../common/ModalV2";
import {TeamMember} from "../../api/dto/Team";
import UpdateMember from "./UpdateMember";
import {useGetUser} from "../../api/userHook";

interface Props {
    teamId: string
}

export default function ListMembersOfTeam(props: Props) {
    const teamId = props.teamId
    const {t} = useTranslation('team')

    const userQuery = useGetUser()
    const user = userQuery.data

    const teamQuery = useGetTeam(teamId)
    const team = teamQuery.data

    const [updatingMember, setUpdatingMember] = useState<TeamMember | undefined>()
    const [updateModalIsOpened, setUpdateModalIsOpened] = useState(false)
    const handleOpenUpdateMemberModal = (member: TeamMember) => {
        setUpdatingMember(member)
        setUpdateModalIsOpened(true)
    }

    const [deletingMember, setDeletingMember] = useState<TeamMember | undefined>()
    const [deleteModalIsOpened, setDeleteModalIsOpened] = useState(false)
    const deleteTeamMemberMutation = useDeleteTeamMember(() => setDeleteModalIsOpened(false))
    const handleOpenDeleteMemberModal = (member: TeamMember) => {
        setDeletingMember(member)
        setDeleteModalIsOpened(true)
    }
    const handleDeleteMemberSubmit = (memberId: string) => {
        deleteTeamMemberMutation.mutate({teamId: teamId, memberId: memberId})
    }

    if (teamQuery.error || userQuery.error) {
        return <></>
    }
    if (teamQuery.isLoading || !team || userQuery.isLoading || !user) {
        return <></>
    }
    return <div>
        <table className="table-container w-full mt-4">
            <thead>
            <tr>
                <th className="text-left">{t('members.data.login.label')}</th>
                <th className="text-left">{t('members.data.membershipDate.label')}</th>
                <th className="text-left">{t('members.data.permissions.label')}</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {
                team.members
                    .sort((a, b) => a.login > b.login ? 1 : -1)
                    .map(member =>
                        <tr key={member.login}>
                            <td>{member.login}</td>
                            <td><DateFormat date={member.member_since} format={Format.NumericDateTime}/></td>
                            <td className="flex flex-col">
                                <div className={"mb-1 badge badge-outline " + (member.site_permission ? "badge-success" : "badge-error")}>
                                    {
                                        member.site_permission ?
                                            <img
                                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA9klEQVR4nNWSv0tCARDHX0s5FGqvO380uAiBc00OQgjdV0WnFlf/jzZbpaFNed2JEW/pL2h1tqlFcBGkRZeGQLJ4g0tYvOcQ9B3v7nO/Hedfyu1fnGwNp0xaZHjP38he9Mr35SyrzFnlaqvqpPJIhueCf7kbGWZFkxVLV3EaGc4MakekeGWV6x+D3CDzp7OzyUcmD2x4yXml2Ga4Wz8gxRuZDL5vl7XSYJMP8lD8tc10X87YZEaGYbonFNiSfjnOJlMydELNenhXPWbFiA3j4FlY4ZFhQn5p3wmruNdIkMoTmyxYsUoZzkPDawV3ZsMtqbQjw3+uL6dxSc38f2ckAAAAAElFTkSuQmCC"/> :
                                            <img
                                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAh0lEQVR4nGNgGH7gPwMDIzlycAWPZNw3PZLxyGJAA49kPJIeynrs+M8QysyADzyW9kx9KOv+94Gcex6qZve/D2U9KvBqhoFHMu4pEA3u+Y9kPJIfyrr/AbEZSAEP5TzSoIb8JVkzxQY8gnoBFA4k+/8xNBAfyXjkIgwk0pD/1IjG/5QkpKEJAALIVChHlltoAAAAAElFTkSuQmCC"/>
                                    }
                                    {t('members.data.permissions.site')}
                                </div>
                                <div className={"mb-1 badge badge-outline " + (member.audit_permission ? "badge-success" : "badge-error")}>
                                    {
                                        member.audit_permission ?
                                            <img
                                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA9klEQVR4nNWSv0tCARDHX0s5FGqvO380uAiBc00OQgjdV0WnFlf/jzZbpaFNed2JEW/pL2h1tqlFcBGkRZeGQLJ4g0tYvOcQ9B3v7nO/Hedfyu1fnGwNp0xaZHjP38he9Mr35SyrzFnlaqvqpPJIhueCf7kbGWZFkxVLV3EaGc4MakekeGWV6x+D3CDzp7OzyUcmD2x4yXml2Ga4Wz8gxRuZDL5vl7XSYJMP8lD8tc10X87YZEaGYbonFNiSfjnOJlMydELNenhXPWbFiA3j4FlY4ZFhQn5p3wmruNdIkMoTmyxYsUoZzkPDawV3ZsMtqbQjw3+uL6dxSc38f2ckAAAAAElFTkSuQmCC"/> :
                                            <img
                                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAh0lEQVR4nGNgGH7gPwMDIzlycAWPZNw3PZLxyGJAA49kPJIeynrs+M8QysyADzyW9kx9KOv+94Gcex6qZve/D2U9KvBqhoFHMu4pEA3u+Y9kPJIfyrr/AbEZSAEP5TzSoIb8JVkzxQY8gnoBFA4k+/8xNBAfyXjkIgwk0pD/1IjG/5QkpKEJAALIVChHlltoAAAAAElFTkSuQmCC"/>
                                    }
                                    {t('members.data.permissions.audit')}
                                </div>
                                <div className={"mb-1 badge badge-outline " + (member.use_credits_permission ? "badge-success" : "badge-error")}>
                                    {
                                        member.use_credits_permission ?
                                            <img
                                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA9klEQVR4nNWSv0tCARDHX0s5FGqvO380uAiBc00OQgjdV0WnFlf/jzZbpaFNed2JEW/pL2h1tqlFcBGkRZeGQLJ4g0tYvOcQ9B3v7nO/Hedfyu1fnGwNp0xaZHjP38he9Mr35SyrzFnlaqvqpPJIhueCf7kbGWZFkxVLV3EaGc4MakekeGWV6x+D3CDzp7OzyUcmD2x4yXml2Ga4Wz8gxRuZDL5vl7XSYJMP8lD8tc10X87YZEaGYbonFNiSfjnOJlMydELNenhXPWbFiA3j4FlY4ZFhQn5p3wmruNdIkMoTmyxYsUoZzkPDawV3ZsMtqbQjw3+uL6dxSc38f2ckAAAAAElFTkSuQmCC"/> :
                                            <img
                                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAh0lEQVR4nGNgGH7gPwMDIzlycAWPZNw3PZLxyGJAA49kPJIeynrs+M8QysyADzyW9kx9KOv+94Gcex6qZve/D2U9KvBqhoFHMu4pEA3u+Y9kPJIfyrr/AbEZSAEP5TzSoIb8JVkzxQY8gnoBFA4k+/8xNBAfyXjkIgwk0pD/1IjG/5QkpKEJAALIVChHlltoAAAAAElFTkSuQmCC"/>
                                    }
                                    {t('members.data.permissions.useCredit')}
                                </div>
                            </td>
                            <td>
                                {
                                    member.login.includes('@') && (user.is_admin || team.admin_login === user.login) &&
                                    <div className="flex flex-col">
                                        <button
                                            className="btn btn-primary btn-sm mb-2"
                                            onClick={() => handleOpenDeleteMemberModal(member)}
                                        >
                                            {t('members.actions.delete.submit')}
                                        </button>
                                        <button
                                            className="btn btn-primary btn-sm"
                                            onClick={() => handleOpenUpdateMemberModal(member)}
                                        >
                                            {t('members.actions.edit.submit')}
                                        </button>
                                    </div>
                                }
                            </td>
                        </tr>
                    )
            }
            </tbody>
        </table>
        <ModalV2 id="update-member" visible={updateModalIsOpened} onClose={() => setUpdateModalIsOpened(false)}>
            <>
                {
                    updatingMember && <UpdateMember teamId={teamId} teamMember={updatingMember} onSuccess={() => setUpdateModalIsOpened(false)}/>
                }
            </>
        </ModalV2>
        <ModalV2 id="delete-member" visible={deleteModalIsOpened} onClose={() => setDeleteModalIsOpened(false)}>
            <>
                {
                    deletingMember &&
                    <div>
                        <p>
                            <Trans t={t} i18nKey="members.actions.delete.confirm.message" values={{login: deletingMember.login}}/>
                        </p>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => handleDeleteMemberSubmit(deletingMember.id)}
                            disabled={deleteTeamMemberMutation.isLoading}
                        >
                            {t('members.actions.delete.confirm.accept')}
                        </button>
                    </div>
                }
            </>
        </ModalV2>
    </div>
}