import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {User} from "./dto/User";
import auditApiClient from "./auditApiClient";
import {SignUpRequest} from "./dto/SignUpRequest";
import {LanguageUpdate} from "./dto/Language";

export const userKeys = {
    me: ['userInfo'] as const,
    all: ['users'] as const,
    lists: () => [...userKeys.all, 'list'] as const,
    list: (siteId: string) => [...userKeys.lists(), {siteId: siteId}] as const,
    details: () => [...userKeys.all, 'detail'] as const,
    detail: (id: string) => [...userKeys.details(), id] as const,
}

export function useGetUser(enabled?: boolean) {
    return useQuery<User, Error>({
        queryKey: [userKeys.me],
        queryFn: () => auditApiClient.getUserInfo(),
        enabled: enabled
    })
}

export function useSignUp(onSuccess?: () => void, onError?: (error: Error) => void) {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (signUp: SignUpRequest) => auditApiClient.signUp(signUp),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: userKeys.all})
            if (onSuccess) {
                onSuccess()
            }
        },
        onError: (error: Error) => {
            if (onError) {
                onError(error)
            }
        }
    })
}

export function useUpdateLanguage(onSuccess?: () => void, onError?: () => void) {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (language: LanguageUpdate) => auditApiClient.updateUserLanguage(language),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: userKeys.lists()})
            if (onSuccess) {
                onSuccess()
            }
        },
        onError: () => {
            if (onError) {
                onError()
            }
        }
    })
}
