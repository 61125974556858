import React, {useEffect, useState} from "react"
import {Trans, useTranslation} from "react-i18next";
import DateFormat, {Format} from "../common/DateFormat";
import WebvertRating from "../common/WebvertRating";
import {Decarbonation} from "../../api/dto/Decarbonation";
import WebvertScore from "../audit/WebvertScore";

type DecarbonationScoreEvolutionProps = {
    decarbonation: Decarbonation,
}

export default function DecarbonationScoreEvolution(props: DecarbonationScoreEvolutionProps) {
    const {t} = useTranslation('decarbonation')

    return <>
        {
            (props.decarbonation.start_site_audit && props.decarbonation.end_site_audit) &&
            <p>
                <Trans
                    t={t}
                    i18nKey='report.scoreEvolution.globalGain'
                    values={{value: Math.round((1 - props.decarbonation.end_site_audit.webvert_score / props.decarbonation.start_site_audit.webvert_score) * 100)}}
                />
            </p>
        }
        <div className="flex flex-wrap lg:flex-nowrap flew-row gap-4 ">
            {
                props.decarbonation.start_site_audit &&
                <div className="lg:w-1/2">
                    <h4>{t('report.scoreEvolution.epdBefore.title')}</h4>
                    <p>
                        {
                            t(
                                'report.scoreEvolution.epdBefore.text',
                                {
                                    rating: props.decarbonation.start_site_audit.webvert_rating,
                                    score: props.decarbonation.start_site_audit.webvert_score
                                }
                            )
                        }
                    </p>
                    <WebvertScore score={props.decarbonation.start_site_audit.webvert_score}
                                  rating={props.decarbonation.start_site_audit.webvert_rating}
                                  className="mt-4"/>
                </div>
            }
            {
                props.decarbonation.end_site_audit &&
                <div className="lg:w-1/2">
                    <h4>{t('report.scoreEvolution.epdAfter.title')}</h4>
                    <p>
                        {
                            t(
                                'report.scoreEvolution.epdAfter.text',
                                {
                                    rating: props.decarbonation.end_site_audit.webvert_rating,
                                    score: props.decarbonation.end_site_audit.webvert_score
                                }
                            )
                        }
                    </p>
                    <WebvertScore score={props.decarbonation.end_site_audit.webvert_score}
                                  rating={props.decarbonation.end_site_audit.webvert_rating}
                                  className="mt-4"/>
                </div>
            }
        </div>
    </>

}
