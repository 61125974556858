import React, {ChangeEvent, FormEvent, useEffect, useReducer, useState} from "react";
import {useTranslation} from "react-i18next";
import {NotificationManager} from "react-notifications";
import {ERROR_TIMEOUT, SUCCESS_TIMEOUT} from "../../constants/notifications";
import {CLIENT_CREDIT_FREE_URLS, CLIENT_CREDIT_URL_INC, USE_USER_CREDITS} from "../../constants/audit";
import {useNavigate, useParams} from "react-router-dom";
import ROUTES_CLIENT from "../../constants/routesClient";
import {SiteAudit, SiteAuditCreation} from "../../api/dto/SiteAudit";
import {useCreateSiteAudit} from "../../api/siteAuditHooks";
import ClientSiteHeader from "../../components/common/ClientSiteHeader";
import {useGetSite} from "../../api/siteHook";
import btn from "../../assets/images/diag_btn_pic.svg";
import info from "../../assets/images/info.svg";
import {useGetUser} from "../../api/userHook";
import {useGetTeams} from "../../api/teamHook";
import {usableTeamCredits} from "../../utils/utils";
import {Team} from "../../api/dto/Team";


export default function RunAudit() {
    let params = useParams()
    const {t} = useTranslation('audit')
    const currentSiteId = params.site_id!
    const {data: user} = useGetUser()
    const {data: site} = useGetSite(currentSiteId!!, !!currentSiteId)
    const [creditCost, setCreditCost] = useState(0)
    const [maxUrls, setMaxUrls] = useState(CLIENT_CREDIT_FREE_URLS)
    const [custom, setCustom] = useState(false)
    const [note, setNote] = useState(false)
    const [teamsWithUsableCredit, setTeamsWithUsableCredit] = useState<Team[]>([])
    const [creditSource, setCreditSource] = useState<string>(USE_USER_CREDITS)


    const [form, setForm] = useReducer(
        (prevState: SiteAuditCreation, updatedValues: Partial<SiteAuditCreation>) => {
            return {...prevState, ...updatedValues}
        },
        {
            site_id: currentSiteId!,
            audit_tag: '',
            max_urls: CLIENT_CREDIT_FREE_URLS,
            use_webvert_user_agent: false
        }
    )
    const {data: teams} = useGetTeams(currentSiteId, true)
    useEffect(() => {
            if (currentSiteId && user && teams) {
                const teamList = usableTeamCredits(currentSiteId, user, teams)
                setTeamsWithUsableCredit(teamList)
                if (teamList.length > 0) {
                    setCreditSource(teamList[0].id)
                }
            }
        }, [teams, user]
    );

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        var value = 0
        if (event.target.name === "max_urls") {
            setCustom(event.target.value === "custom")
            if (event.target.value !== "custom") {
                value = Number(event.target.value)
                setMaxUrls(value)
            } else {
                value = maxUrls
            }
        }
        if (event.target.name === "custom_max_urls") {
            value = Number(event.target.value)
            setMaxUrls(value)
        }
        if (event.target.name === "max_urls" || event.target.name === "custom_max_urls") {
            setCreditCost(Math.ceil((value - CLIENT_CREDIT_FREE_URLS) / CLIENT_CREDIT_URL_INC))
        } else {
            setForm({[event.target.name]: event.target.value});
        }
    }

    const navigate = useNavigate()
    const runAuditMutation = useCreateSiteAudit(
        (siteAudit: SiteAudit) => {
            NotificationManager.success(t('new.feedbacks.success.message'), t('new.feedbacks.success.title'), SUCCESS_TIMEOUT)
            navigate(ROUTES_CLIENT.AUDITS_DASHBOARD.withParams(form.site_id))
        },
        () => NotificationManager.error(t('new.feedbacks.error.message'), t('new.feedbacks.error.title'), ERROR_TIMEOUT)
    )
    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        runAuditMutation.mutate({
            site_id: form.site_id,
            audit_tag: form.audit_tag,
            max_urls: maxUrls,
            use_webvert_user_agent: false,
            team_that_spends_credits: creditSource === USE_USER_CREDITS ? "" : creditSource
        })
    }

    return <div>
        <ClientSiteHeader backUrl={ROUTES_CLIENT.HOME.path} siteName={site?.name!} siteUrl={site?.url!} siteFavicon={site?.favicon_url!}/>
        <div className="w-full min-h-screen bg-base-200 py-6 px-4">
            <div className="max-w-6xl mx-auto">
                <div className="bg-white rounded rounded-lg  w-full p-4 mb-8">
                    <h2 className="text-xl ">{t('actions.run.title')}</h2>
                    <p className="text-sm text-neutral-focus">{t('actions.run.description')}</p>
                    <form onSubmit={handleSubmit}>
                        <div className="form-control">
                            <label htmlFor="maxUrl" className="label font-bold">{t('data.maxUrl.label')}</label>

                            <div className="grid grid-cols-2 lg:grid-cols-5 gap-5">
                                <div className="py-4 px-4 flex items-center bg-base-100 rounded-lg">
                                    <input type="radio" id="credit_50" name="max_urls" value="50"
                                           checked={!custom && maxUrls === 50} onChange={handleChange}/>
                                    <label htmlFor="credit_50" className="ml-2 cursor-pointer">50 <span
                                        className="text-sm text-neutral-focus">(0 {t('credits:item')})</span></label>
                                </div>
                                <div className="py-4 px-4 flex items-center bg-base-100 rounded-lg">
                                    <input type="radio" id="credit_350" name="max_urls" value="350"
                                           checked={!custom && maxUrls === 350} onChange={handleChange}/>
                                    <label htmlFor="credit_350" className="ml-2 cursor-pointer">350 <span
                                        className="text-sm text-neutral-focus">(1 {t('credits:item')})</span></label>
                                </div>
                                <div className="py-4 px-4 flex items-center bg-base-100 rounded-lg">
                                    <input type="radio" id="credit_650" name="max_urls" value="650"
                                           checked={!custom && maxUrls === 650} onChange={handleChange}/>
                                    <label htmlFor="credit_650"
                                           className="ml-2 cursor-pointer">650 <span className="text-sm text-neutral-focus">(2 {t('credits:items')})</span></label>
                                </div>
                                <div className="py-4 px-4 flex items-center bg-base-100 rounded-lg">
                                    <input type="radio" id="credit_950" name="max_urls" value="950"
                                           checked={!custom && maxUrls === 950} onChange={handleChange}/>
                                    <label htmlFor="credit_950"
                                           className="ml-2 cursor-pointer">950 <span className="text-sm text-neutral-focus">(3 {t('credits:items')})</span></label>
                                </div>
                                <div className="py-4 px-4 flex items-center bg-base-100 rounded-lg">
                                    <input type="radio" id="custom_amount" name="max_urls" value="custom"
                                           checked={custom} onChange={handleChange}/>
                                    <label htmlFor="custom_amount"
                                           className="ml-2 cursor-pointer">Personnalisé</label>
                                </div>
                            </div>
                            <div className={"flex w-full rounded-lg " + (!custom ? " hidden" : "")}>
                                <input type="number" id="custom_max_urls" name="custom_max_urls"
                                       className="p-2 input input-bordered rounded text-right w-full"
                                       value={maxUrls} onChange={handleChange}/>
                            </div>
                        </div>
                        <div className="form-control">
                            <label htmlFor="audit_tag" className="label" onClick={() => setNote(!note)} tabIndex={0}
                                   role="button"
                                   aria-label="open" aria-pressed={note} aria-expanded={!note} onKeyDown={() => setNote(!note)}>
                                {t('data.tag.labelForm')}
                            </label>
                            <input tabIndex={note ? 0 : 1}
                                   id="audit_tag"
                                   name="audit_tag"
                                   className={"input input-bordered w-full max-w-x" + (!note ? " hidden" : "")}
                                   type="text"
                                   placeholder={t('data.tag.placeHolder')}
                                   onChange={handleChange}
                            />
                        </div>
                        <div className="relative inline-block  w-full">

                            {user && <>
                                {teamsWithUsableCredit.length > 0 ?
                                    <div className="pb-4">
                                        <legend className="font-bold">
                                            {t('credits:confirm.fixes.credits_source')}
                                        </legend>
                                        {
                                            teamsWithUsableCredit.map((currentTeam, i) => {
                                                return <div className="pl-8 mt-2" key={currentTeam.id}>
                                                    <input type="radio"
                                                           id={currentTeam.id}
                                                           name="creditsource"
                                                           value={currentTeam.id}
                                                           aria-labelledby={"team-" + currentTeam.id}
                                                           checked={creditSource === currentTeam.id}
                                                           onChange={e => setCreditSource(currentTeam.id)}
                                                    />
                                                    <label className="pl-2 label-text"
                                                           id={"team-" + currentTeam.id}
                                                           htmlFor={currentTeam.id}
                                                    >
                                                        {t('credits:confirm.fixes.credits_team')} "{currentTeam.name}"
                                                        : {currentTeam.credits} {t('credits:management.available', {s: (currentTeam.credits > 0 ? "s" : "")})}
                                                    </label>
                                                </div>
                                            })
                                        }
                                        <div className="pl-8 mt-2">
                                            <input type="radio"
                                                   id="user-credit"
                                                   name="creditsource"
                                                   value={USE_USER_CREDITS}
                                                   aria-labelledby="user-credit"
                                                   checked={creditSource === USE_USER_CREDITS}
                                                   onChange={e => setCreditSource(USE_USER_CREDITS)}
                                            />
                                            <label className="pl-2 label-text" id="user-credit" htmlFor="user-credit">
                                                {t('credits:confirm.fixes.credits_user')} {user?.credits} {t('credits:management.available', {s: (user!.credits > 0 ? "s" : "")})}
                                            </label>
                                        </div>
                                    </div>
                                    :
                                    <p className="text-center text-xs">
                                        <img src={info} aria-hidden={true} alt="Info" className="inline"/>
                                        <span className="align-middle">
                                &nbsp;
                                            {user?.credits} {t('credits:management.available', {s: (user!.credits > 0 ? "s" : "")})}
                            </span>
                                    </p>
                                }
                            </>
                            }
                            <div className="my-4 w-full">
                                <input
                                    id="submit"
                                    type="submit"
                                    className="btn btn-primary btn-wide w-full"
                                    value={creditCost > 1 ? t('actions.run.buttonCredit', {
                                        nb: creditCost,
                                        s: 's'
                                    }) : t('actions.run.buttonCredit', {nb: creditCost, s: ''})}
                                    disabled={!currentSiteId || runAuditMutation.isLoading}
                                />
                                <img src={btn} alt="" className="absolute -right-2 top-2/3 transform -translate-y-1/3" aria-hidden="true"/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
}
